import ApplicationController from "./application_controller"
import { delay } from "../helpers"

/**
 * Handles streaming text responses from server-sent events
 * Uses ARIA attributes for state management and accessibility
 */
export default class extends ApplicationController {
  static targets = ["output"]
  static values = {
    endpoint: String
  }

  connect() {
    super.connect()
    this.messageQueue = Promise.resolve() // Initialize message queue
    this.start()
  }

  disconnect() {
    super.disconnect()

    this.closeEventSource()
  }

  start() {
    if (!this.hasEndpointValue) return
    if (!this.hasOutputTarget) return

    this.reset()
    this.setState("loading") // Initial loading state when starting
    this.stream()
  }

  // Private

  stream() {
    this.eventSource = new EventSource(this.endpointValue)
    this.eventSource.onmessage = this.didReceiveMessage
    this.eventSource.onerror = this.didError
    this.eventSource.addEventListener("finished", this.didFinish)
  }

  reset() {
    this.closeEventSource()
    this.clearOutput()
    this.setState("idle")
  }

  closeEventSource() {
    this.eventSource?.close()
    this.eventSource = null
  }

  setState(state) {
    // Clear previous states
    this.element.removeAttribute("data-state")
    this.element.setAttribute("aria-busy", false)
    this.element.setAttribute("aria-live", "off")
    this.element.setAttribute("aria-status", "")

    // Set new state
    this.element.setAttribute("data-state", state)

    switch (state) {
      case "loading":
        this.element.setAttribute("aria-busy", true)
        break
      case "streaming":
        this.element.setAttribute("aria-busy", true)
        this.element.setAttribute("aria-live", "polite")
        break
      case "finished":
        this.element.setAttribute("aria-live", "off")
        break
      case "error":
        this.element.setAttribute("aria-invalid", true)
        break
    }
  }

  get isTextField() {
    return ["TEXTAREA", "INPUT"].includes(this.outputTarget.tagName)
  }

  clearOutput() {
    this.isTextField
      ? this.outputTarget.value = ""
      : this.outputTarget.textContent = ""
  }

  async appendOutput(text) {
    const minDelay = 5  // milliseconds
    const maxDelay = 15  // milliseconds
    const wait = Math.random() * (maxDelay - minDelay) + minDelay;

    await delay(wait);

    this.isTextField
      ? this.outputTarget.value += text
      : this.outputTarget.textContent += text
  }

  async processMessage(text) {
    for (const char of text) {
      await this.appendOutput(char)
    }
  }

  // Event handlers

  didReceiveMessage = (event) => {
    // Set streaming state on first message
    if (this.element.getAttribute("data-state") === "loading") {
      this.setState("streaming")
    }

    this.messageQueue = this.messageQueue.then(
      async () => await this.processMessage(event.data)
    )
  }

  didError = () => {
    this.closeEventSource()
    this.setState("error")
  }

  didFinish = () => {
    this.closeEventSource()

    // Wait for all messages to be processed before setting finished state
    this.messageQueue = this.messageQueue.then(
      async () => this.setState("finished")
    )
  }
}
