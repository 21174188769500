import ApplicationController from "./application_controller"
import { delay } from "../helpers"

const SCRIPT_URL = "https://chatwidget-prod.web.app/embed/init.iife.js"
const WIDGET_ID = "MNZq7MHWzzv"
const SETTINGS_ID = "13551"

export default class extends ApplicationController {
  _observer = null
  _pendingOpen = false

  connect () {
    super.connect()
    this.element.ariaBusy = true

    this.resetWidget()
      .then(() => this.initializeWidget())
      .catch((error) => {
        console.error("Failed to initialize Watermelon:", error)
        this.element.ariaBusy = false

        this.dispatch("watermelon:error", { detail: { error } })
      })
  }

  disconnect () {
    super.disconnect()
    this._observer?.disconnect()
    this._pendingOpen = false
  }

  open () {
    if (this.widgetButtonElement) {
      const button = this.widgetButtonElement.shadowRoot.querySelector("button")
      if (button) button.click()
    } else {
      this._pendingOpen = true
    }
  }

  // Private

  async resetWidget () {
    this.widgetButtonElement?.classList.add("hidden")
    this.removeWidgetElements()
    this.removeGlobalState()

    await delay(50) // Brief delay to ensure cleanup
  }

  initializeWidget () {
    this.createScriptElement()
    this.watchForWidgetButton()
  }

  watchForWidgetButton () {
    this._observer?.disconnect()

    this._observer = new MutationObserver(async () => {
      await delay()

      if (this.widgetButtonElement) {
        this._observer.disconnect()
        this.onWidgetReady()
      }
    })

    this._observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  }

  onWidgetReady () {
    this.widgetButtonElement.classList.add("hidden")

    this.dispatch("watermelon:ready", {
      detail: { element: this.widgetButtonElement },
    })

    this.element.ariaBusy = false

    if (this._pendingOpen) {
      this._pendingOpen = false
      this.open()
    }
  }

  createScriptElement () {
    this.scriptElement?.remove()

    const script = document.createElement("script")
    script.src = SCRIPT_URL
    script.id = this.scriptElementId
    script.dataset.watermelonWidgetId = WIDGET_ID
    script.dataset.watermelonSettingsId = SETTINGS_ID

    document.head.appendChild(script)
  }

  removeWidgetElements () {
    this.widgetWrapperElement?.remove()
    this.widgetButtonElement?.remove()
    this.widgetStylesElement?.remove()
  }

  removeGlobalState () {
    if (window.watermelonWidget) {
      window.removeEventListener("message", window.watermelonWidget.onCommand)
      window.watermelonWidget = null
    }
  }

  // Element Getters

  get widgetButtonElement () {
    return document.querySelector("watermelon-widget-button")
  }

  get widgetWrapperElement () {
    return document.querySelector("#watermelon-widget-wrapper")
  }

  get widgetStylesElement () {
    return document.querySelector("#watermelon-yg-css-styles")
  }

  get scriptElement () {
    return document.querySelector("#watermelon-widget-script")
  }

  // Constants

  get scriptElementId () {
    return "watermelon-widget-script"
  }
}
