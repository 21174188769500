import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["message"]

  connect() {
    super.connect()

    this.start()
  }

  disconnect() {
    super.disconnect()

    clearInterval(this.interval)
  }

  start() {
    this.messageTargets[0].setAttribute("aria-current", "true")

    this.interval = setInterval(() => {
      this.nextTarget.setAttribute("aria-current", "true")
      this.activeTarget.removeAttribute("aria-current")
    }, 1500)
  }

  get activeTarget() {
    return this.element.querySelector('[aria-current="true"]')
  }

  get nextTarget() {
    const currentIndex = this.messageTargets.indexOf(this.activeTarget)
    const nextIndex = (currentIndex + 1) % this.messageTargets.length

    return this.messageTargets[nextIndex]
  }
}
